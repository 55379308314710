import Vue from "vue";
import Cookie from "js-cookie";
import placeholders from "@/assets/js/placeholders";
import filtersData from "@/assets/js/filters";
import copyVariables from "@/assets/js/variables";
import { mapMutations } from "vuex";

const ShowUserFormComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/User.vue"
  );
const ShowResetPasswordFormComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/ResetPassword.vue"
  );
const companyFormComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/Company.vue"
  );
const addressFormComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/Address.vue"
  );
const cashbackFormComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/Cashback.vue"
  );

const dealFormComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/Deal.vue"
  );

const slideFormComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/Slide.vue"
  );

const voucherFormComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/Voucher.vue"
  );

const autopricingFormComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/Autopricing.vue"
  );  

const orderFormComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/Order.vue"
  );

const orderClaimComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/OrderClaim.vue"
  );

const agentFormComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/Agent.vue"
  );

const orderAddressesComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/OrderAddresses.vue"
  );

const batchComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/EditBatch.vue"
  );

const paymentWidgetComponent = () =>
  import(
    /* webpackChunkName: "ShowUserForm" */ "@/components/drawers/Payment.vue"
  );

const catalogInventoryComponent = () =>
  import(
    /* webpackChunkName: "CatalogInventory" */ "@/components/drawers/Inventory.vue"
  );

const reviewComponent = () =>
  import(
    /* webpackChunkName: "CatalogInventory" */ "@/components/drawers/Review.vue"
  );

const pageComponent = () =>
  import(
    /* webpackChunkName: "CatalogInventory" */ "@/components/drawers/Page.vue"
  );

const countryComponent = () =>
  import(
    /* webpackChunkName: "CatalogInventory" */ "@/components/drawers/Country.vue"
  );

const ConfigVarsComponent = () =>
  import(
    /* webpackChunkName: "CatalogInventory" */ "@/components/drawers/ConfigVars.vue"
  );

const userMailingComponent = () =>
  import(
    /* webpackChunkName: "CatalogInventory" */ "@/components/drawers/userMailing.vue"
  );

const helpers = Vue.mixin({
  data() {
    return {
      confirm_deleting: false,
      placeholders,
      copyVariables,
      filtersData
    };
  },
  methods: {
    ...mapMutations({
      updateTitle: "UPDATE_TITLE"
    }),
    view(name) {
      return () => import(name);
    },
    downloadInvoice(order_id = null) {
      let in_order_currency = "";
      this.$axios
        .get("ecommerce/orders/" + order_id + "/invoice" + in_order_currency)
        .then(res => {        
          for (let i = 0; i < res.data.length; i++) {
            var base64Content = res.data[i].content;
            var filename = res.data[i].name;
        
            var binaryData = atob(base64Content); 
        
            var arrayBuffer = new ArrayBuffer(binaryData.length);
            var uint8Array = new Uint8Array(arrayBuffer);
        
            for (var j = 0; j < binaryData.length; j++) {
                uint8Array[j] = binaryData.charCodeAt(j);
            }
        
            var blob = new Blob([arrayBuffer], { type: 'application/pdf' });
        
            // IE-specific handling (msSaveBlob)
            if (window.navigator.msSaveOrOpenBlob) {
                // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
                window.navigator.msSaveBlob(blob, filename);
            } else {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.download = filename;
        
                document.body.appendChild(a);
                a.click(); // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
                document.body.removeChild(a);
            }
          }
        })
        .catch(e => this.clientError(e));
    },
    openDrawer(action, options = null) {
      if (!options.level) options.level = "main";
      if (!options.background) options.background = false;

      let width = {
        sm: "small",
        md: "medium",
        lg: "large",
        ticket: "ticket"
      };

      let drawer_options = {
        level: options.level,
        show: true,
        content: null,
        width: options.width || width.md,
        background: options.background,
        key: options.key ? options.key : action,
        options: {}
      };

      if (action === "openUserForm") {
        drawer_options.content = ShowUserFormComponent;
        drawer_options.options = options;
      } else if (action === "resetAgentPassword") {
        drawer_options.content = ShowResetPasswordFormComponent;
        drawer_options.options = options;
      } else if (action === "companyForm") {
        drawer_options.content = companyFormComponent;
        drawer_options.options = options;
      } else if (action === "addressForm") {
        drawer_options.content = addressFormComponent;
        drawer_options.options = options;
      } else if (action === "cashbackForm") {
        drawer_options.content = cashbackFormComponent;
        drawer_options.options = options;
      } else if (action === "dealForm") {
        drawer_options.content = dealFormComponent;
        drawer_options.options = options;
        drawer_options.width = width.lg;
      } else if (action === "slideForm") {
        drawer_options.content = slideFormComponent;
        drawer_options.options = options;
        drawer_options.width = width.lg;
      } else if (action === "voucherForm") {
        drawer_options.content = voucherFormComponent;
        drawer_options.options = options;
      } else if (action === "autopricingForm") {
        drawer_options.content = autopricingFormComponent;
        drawer_options.options = options;
      } else if (action === "orderForm") {
        drawer_options.content = orderFormComponent;
        drawer_options.options = options;
      } else if (action === "agentForm") {
        drawer_options.content = agentFormComponent;
        drawer_options.options = options;
      } else if (action === "orderAddresses") {
        drawer_options.content = orderAddressesComponent;
        drawer_options.options = options;
      } else if (action === "orderClaim") {
        drawer_options.content = orderClaimComponent;
        drawer_options.options = options;
        // drawer_options.width = width.sm;
      } else if (action === "editBatch") {
        drawer_options.content = batchComponent;
        drawer_options.options = options;
      } else if (action === "paymentWidget") {
        drawer_options.content = paymentWidgetComponent;
        drawer_options.options = options;
      } else if (action === "catalogInventory") {
        drawer_options.content = catalogInventoryComponent;
        drawer_options.options = options;
        drawer_options.width = width.lg;
      } else if (action === "reviewForm") {
        drawer_options.content = reviewComponent;
        drawer_options.options = options;
      } else if (action === "pageForm") {
        drawer_options.content = pageComponent;
        drawer_options.options = options;
      } else if (action === "ConfigVarsForm") {
        drawer_options.content = ConfigVarsComponent;
        drawer_options.options = options;
      } else if (action === "userMailing") {
        drawer_options.content = userMailingComponent;
        // drawer_options.width = width.sm;
        drawer_options.options = options;
      } else if (action === "editCountry") {
        drawer_options.content = countryComponent;
        drawer_options.options = options;
        drawer_options.width = width.sm;
      }

      this.$store.commit("OPEN_RIGHT_DRAWER", drawer_options);

      if (options != null && "close_after" in options) {
        var self = this;
        setTimeout(function() {
          self.closeDrawer();
        }, options.close_after);
      }
    },
    closeDrawer(level = "main") {
      this.$store.commit("CLOSE_RIGHT_DRAWER", level);
      this.$store.commit("UPDATE_DRAWER_LOADING", false);
    },
    signout() {
      this.$axios
        .get("me/signout")
        .then(() => {
          Cookie.remove("token");
          window.location.href = "/signin";
        })
        .catch(e => this.clientError(e));
    },
    clientError(e) {
      const code = parseInt(e.response && e.response.status);
      const message = e.response && e.response.data && e.response.data.message;
      if (code) {
        if (code === 400) this.notify(message, "is-warning");
        else if (code === 404) this.notify(message, "is-warning");
        else if (code === 422) this.notify(message, "is-warning");
        else if (code === 403) this.notify(message, "is-warning");
        else if (code === 500) this.notify(message, "is-danger");
      }
    },
    notify(message, type, duration = 2000, position = "is-top") {
      this.$buefy.toast.open({
        duration: duration,
        message: `<span class="has-text-weight-semibold">${message}</span>`,
        position: position,
        queue: false,
        pauseOnHover: true,
        type: type
      });
    },
    copytoClipboard(text) {
      if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText(text);
        this.notify(this.$t("copied"), "is-success", 1000);
      }
    },
    getFilterPlaceholder(head) {
      let filters =
        head.filter_type && head.filter_type == "numeric"
          ? this.filtersData.numeric
          : this.filtersData.char;
      let placeholder = filters.find(s => s.value == head.filter);
      return placeholder ? placeholder.label : "";
    },
    getColor(c) {
      if (c === "green") return "is-success";
      else if (c === "yellow") return "is-warning";
      else if (c === "red") return "is-danger";
      else if (c === "blue") return "is-info";
      else return "";
    },
    getUrl(ids, link) {
      let url = link;
      for (let i = 0; i < ids.length; i++) {
        url = url.replace("{id}", ids[i]);
      }
      return url;
    },
    formatFilter(searchArr) {
      let api_params = "&filters=";
      for (let i = 0; i < searchArr.length; i++) {
        let filter =
          searchArr[i].c.toString() +
          ":" +
          searchArr[i].o +
          ":" +
          searchArr[i].v.toString() +
          (i !== searchArr.length - 1 ? "~" : "");
        api_params += filter;
      }
      return api_params;
    }
  }
});

export default helpers;
