<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <div class="columns is-multiline is-mobile">
        <div
          :key="myBlog.img"
          class="column is-12"
        >
          <div
            v-if="showImg"
            class="is-flex is-justify-content-center"
          >
            <div class="upload-img-container">
              <img :src="myBlog.img">

              <span
                class="delete-icon"
                @click="deleteImg"
              >
                <b-icon
                  icon="close"
                  size="is-small"
                />
              </span>
            </div>
          </div>
          <ImageUpload
            v-else
            :images="[]"
            :max-files="1"
            @updateFiles="onUpdateFiles"
          />
        </div>
        <div class="column is-3-tablet is-6-mobile">
          <b-select-validation
            v-model="myBlog.site_id"
            rules="required"
            :label="$t('site')"
          >
            <template>
              <option
                v-for="opt in sites"
                :key="opt.id"
                :value="opt.id"
              >
                {{ opt.label }}
              </option>
            </template>
          </b-select-validation>
        </div>

        <div class="column is-3-tablet is-6-mobile">
          <b-select-validation
            v-model="myBlog.lang_iso"
            rules="required"
            :label="$tc('language', 1)"
          >
            <template>
              <option
                v-for="opt in languages"
                :key="opt.iso"
                :value="opt.iso"
              >
                {{ opt.label }}
              </option>
            </template>
          </b-select-validation>
        </div>

        <div class="column is-4-tablet is-12-mobile">
          <b-select-validation
            v-model="myBlog.category_id"
            rules="required"
            vid="category"
            :label="$tc('category', 1)"
          >
            <template>
              <optgroup
                v-for="opt in categories"
                :key="opt.id"               
                :label="opt.category"
              >
                <option
                  v-for="child in opt.children"
                  :key="child.id"                              
                  :value="child.id"
                >
                  {{ child.category }}
                </option>
              </optgroup>
            </template>
          </b-select-validation>
        </div>

        <div class="column is-2-tablet is-12-mobile">
          <b-input-validation
            v-model="myBlog.read_time"
            :label="`${$t('read_time')} (${$t('optional')})`"
            rules="numeric"
            vid="read_time"
            :placeholder="placeholders.number[1]"
          />
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myBlog.title"
            rules="required|max:150"
            :label="$tc('title', 1)"
            vid="title"
            :placeholder="placeholders.blog.title"
            @blur="slugifyTitle()"
          />
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myBlog.permalink"
            :rules="`required${validPermalink}|max:150`"
            :label="$tc('permalink', 1)"
            validation-mode="lazy"
            :disabled="index !== -1"
            vid="permalink"
            :placeholder="placeholders.blog.permalink"
          />
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myBlog.meta_title"
            rules="max:90"
            :label="$t('meta_title') + ' (' + $t('optional') + ')'"
            vid="meta_title"
            maxlength="90"
            counter="90"
            :placeholder="placeholders.blog.meta_title"
          />
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myBlog.permalink_redirect"           
            :label="$t('permalink_redirect') + ' (' + $t('optional') + ')'"
            vid="permalink_redirect"
            :placeholder="placeholders.blog.permalink_redirect"
            rules="max:150"
          />
        </div>

        <div class="column is-12">
          <b-input-validation
            v-model="myBlog.meta_description"
            input-type="textarea"
            :label="$t('meta_description') + ' (' + $t('optional') + ')'"
            vid="meta_description"
            :placeholder="placeholders.blog.meta_description"
            maxlength="300"
            rows="2"
            counter="300"
          />
        </div>

        <div class="column is-12 mb-4">
          <!-- <b-input-validation
            v-model="myBlog.article"
            rules="required"
            input-type="textarea"
            :label="$tc('article', 1)"
            vid="article"
            :placeholder="placeholders.blog.article"
            rows="10"
          /> -->
          <froala
            ref="editor"
            v-model="myBlog.article"
            class="editor"
            :config="editorConfig"
          />
        </div>

        <!-- recipe info -->

        <div class="column is-12">
          <div class="is-size-5">
            <b>{{ $t("recipe_info") }}</b>
          </div>
        </div>
        <div class="column is-12-mobile">
          <b-input-validation
            v-model="myBlog.proteins"
            :label="`${$t('proteins')} (${$t('optional')})`"
            :placeholder="placeholders.blog.nutrifacts"
          />
        </div>
        <div class="column is-12-mobile">
          <b-input-validation
            v-model="myBlog.carbs"
            :label="`${$t('carbs')} (${$t('optional')})`"
            :placeholder="placeholders.blog.nutrifacts"
          />
        </div>
        <div class="column is-12-mobile">
          <b-input-validation
            v-model="myBlog.calories"
            :label="`${$t('calories')} (${$t('optional')})`"
            :placeholder="placeholders.number[3]"
          />
        </div>
        <div class="column is-12-mobile">
          <b-input-validation
            v-model="myBlog.fats"
            :label="`${$t('fats')} (${$t('optional')})`"
            :placeholder="placeholders.blog.nutrifacts"
          />
        </div>
        <div class="column">
          <b-input-validation
            v-model="myBlog.servings"
            :label="`${$t('servings')} (${$t('optional')})`"
            :placeholder="placeholders.number[1]"
          />
        </div>
        <div class="column is-12-mobile">
          <b-input-validation
            v-model="myBlog.active_time"
            :label="`${$t('active_time')} (${$t('optional')})`"
            :placeholder="placeholders.blog.time"
          />
        </div>
        <div class="column is-12-mobile">
          <b-input-validation
            v-model="myBlog.total_time"
            :label="`${$t('total_time')} (${$t('optional')})`"
            :placeholder="placeholders.blog.time"
          />
        </div>

        <div class="column is-12">
          <b-button
            type="is-primary"
            :disabled="save_loading"
            :loading="save_loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import Cookie from "js-cookie";

import ImageUpload from "@/components/forms/elements/ImageUpload";
export default {
  components: {
    ImageUpload,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    blog: {
      type: Object,
      default: null,
    },
    sites: {
      type: Array,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showImg: false,
      save_loading: false,
      myBlog: {
        site_id: null,
        category_id: null,
        lang_iso: null,
        read_time: null,
        meta_title: "",
        meta_description: "",
        title: "",
        image: "",
        permalink: "",
        permalink_redirect: "",
        article: "",
        active: true,
        proteins: null,
        carbs: null,
        calories: null,
        fats: null,
        servings: null,
        active_time: null,
        total_time: null,
      },
      languages: [],
      image: "",
      images: [],
      editorConfig: {
        key: process.env.VUE_APP_FROALA_KEY,
        toolbarSticky: false,
        attribution: false,
        zIndex: 1,
        entities: "",
        pastePlain: true,
        imageAllowedTypes: ['jpeg', 'jpg', 'png'],
        shortcutsEnabled: ["bold", "italic", "underline", "undo", "redo", "insertImage"],
        pluginsEnabled: [
          "align",
          "charCounter",
          "codeBeautifier",
          "codeView",
          "colors",
          "draggable",
          "embedly",
          "emoticons",
          "specialCharacters",
          "image",
          "imageManager",
          //"imageTUI",
          "video",
          "file",
          "fontAwesome",
          "fontSize",
          "fullscreen",
          "inlineStyle",
          "inlineClass",
          "lineBreaker",
          "lineHeight",
          "link",
          "lists",
          "paragraphFormat",
          "paragraphStyle",
          "quickInsert",
          "entities",
          "quote",
          "save",
          "table",
          "url",
          "wordPaste",
        ],

        toolbarButtons: {
          moreText: {
            buttons: [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "subscript",
              "superscript",
              "textColor",
              "backgroundColor",
              "inlineClass",
              "clearFormatting",
            ],
            buttonsVisible: 2,
          },
          moreParagraph: {
            buttons: [
              "alignLeft",
              "alignCenter",
              "formatOLSimple",
              "alignRight",
              "alignJustify",
              "formatOL",
              "formatUL",
              "paragraphFormat",
              "outdent",
              "indent",
              "quote",
              "insertHR",
            ],
          },
          moreRich: {
            buttons: [
            "insertLink",
            "insertTable",
            "insertImage",
            "insertVideo",
            "specialCharacters",
            "emoticons",
            ],
            buttonsVisible: 6,
          },
          moreMisc: {
            buttons: [
              "undo",
              "redo",
              "fullscreen",
              "selectAll",
              "html",
              "help",
            ],
            align: "right",
            buttonsVisible: 2,
          },
        },
        imageUploadRemoteUrls: false,
        imagePaste: false,
        imageOutputSize: true,
        imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
        imageEditButtons: ['imageReplace', 'imageAlign', 'imageCaption', 'imageRemove', '|', 'imageLink', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'], //'imageTUI'
        imageInsertButtons: [ 'imageUpload', 'imageManager', 'imageByURL'],
        imageStyles: {
          'mt-4': 'Top margin',
          'mb-4': 'Bottom margin',
          'ml-4': 'Left margin',
          'mr-4': 'Margin margin',
        },
        // AJAX REQUEST HEADERS
        requestHeaders: {
          "X-Requested-With": "XMLHttpRequest",
          "X-ORIGIN": process.env.VUE_APP_ORIGIN,
          "Authorization": "Bearer " + Cookie.get("token"),
        },

        // UPLOAD IMAGE CONFIG:
        imageUploadMethod: 'POST',
        imageUploadURL: process.env.VUE_APP_API_URL + "blog/images/manager",
        imageUploadParam: "image",
        imageUploadParams: {
          blog_id: this.blog.id
        },

        // FILE MANAGER
        imageManagerLoadURL: process.env.VUE_APP_API_URL + "blog/images/manager",
        imageManagerLoadMethod: "GET",

        // Delete image
        imageManagerDeleteURL: process.env.VUE_APP_API_URL + "blog/images/manager",
        imageManagerDeleteMethod: "DELETE",


        // Video
        videoAllowedProviders: ['youtube', 'vimeo'],
        videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
        videoEditButtons: ['videoReplace', 'videoRemove', '|', 'videoDisplay', 'videoAlign', 'videoSize'],
        videoUpload: false,


        events: {
          "html.get": function (html) {
            return html.replace(/id="isPasted"/g, "");
          },

          'image.error': function (error, response) {
            // Bad link.
            if (error.code == 1) {}

            // No link in upload response.
            else if (error.code == 2) { }

            // Error during image upload.
            else if (error.code == 3) { }

            // Parsing response failed.
            else if (error.code == 4) { }

            // Image too text-large.
            else if (error.code == 5) { }

            // Invalid image type.
            else if (error.code == 6) { }

            // Image can be uploaded only to same domain in IE 8 and IE 9.
            else if (error.code == 7) {  }

            // Response contains the original server response to the request if available.
          }
        },
      },
    };
  },
  computed: {
    validPermalink() {
      if (
        this.index == -1 &&
        this.myBlog &&
        this.myBlog.permalink &&
        this.myBlog.site_id &&
        this.action == "create"
      )
        return `|valid_blog_permalink:${this.myBlog.permalink}/${this.myBlog.site_id}/check`;
      else return "";
    },
  },
  watch: {
    "myBlog.site_id": function (new_value) {
      this.slugifyTitle();
      let site = this.sites.find((s) => s.id === new_value);
      this.languages = site.languages;
    },
  },
  created() {
    this.editorConfig.placeholderText = this.placeholders.blog.article;
    if (this.action == "update") {
      this.myBlog = { ...this.blog };
      this.myBlog.lang_iso =
        this.blog && this.blog.lang.iso ? this.blog.lang.iso : null;
      if (this.myBlog.site_id) {
        let site = this.sites.find((s) => s.id === this.myBlog.site_id);
        this.languages = site.languages;
      }
      if (this.blog && this.blog.image) {
        let img = this.blog.image;
        this.myBlog.img = `${img.url}${img.path}${img.filename}-w990h360@2x.${img.version}.jpg`;
        this.showImg = true;
      }
    }
  },
  methods: {
    deleteImg() {
      this.myBlog.img = null;
      this.showImg = false;
    },
    slugifyTitle() {
      if (this.myBlog.title.length > 0 && this.action == "create") {
        this.$axios
          .get(
            "blog/permalink/" +
              encodeURIComponent(this.myBlog.title.split("/").join(" ")) +
              "/" +
              this.myBlog.site_id
          )
          .then((res) => {
            this.myBlog.permalink = res.data.permalink;
          })
          .catch((e) => {
            let permalink =
              e.response && e.response.data && e.response.data.permalink;
            this.myBlog.permalink = permalink;
          });
      }
    },
    save() {
      this.save_loading = true;

      let blog_id = "";

      // get formatted HTML if code view is active
      var editor = this.$refs.editor.$el["data-froala.editor"];
      if (editor.codeView.isActive()) {
        editor.html.set(editor.codeView.get());
        editor.events.trigger("contentChanged", [], true);
      }

      this.loading = true;
      const formData = new FormData();
      if (!this.myBlog.img) formData.append("image", this.image);
      formData.set("title", this.myBlog.title);
      formData.set("permalink", this.myBlog.permalink);
      formData.set("permalink_redirect", this.myBlog.permalink_redirect ? this.myBlog.permalink_redirect : '' );
      formData.set("site_id", this.myBlog.site_id);
      formData.set("lang_iso", this.myBlog.lang_iso);
      formData.set("category_id", this.myBlog.category_id);
      formData.set("read_time", this.myBlog.read_time);
      formData.set("meta_title", this.myBlog.meta_title ? this.myBlog.meta_title :'');
      formData.set("meta_description", this.myBlog.meta_description ? this.myBlog.meta_description :'');
      formData.set("article", this.myBlog.article);
      formData.set("proteins", this.myBlog.proteins);
      formData.set("carbs", this.myBlog.carbs);
      formData.set("calories", this.myBlog.calories);
      formData.set("fats", this.myBlog.fats);
      formData.set("servings", this.myBlog.servings);
      formData.set("active_time", this.myBlog.active_time);
      formData.set("total_time", this.myBlog.total_time);

      if (this.action == "update") {
        blog_id = "/" + this.blog.id;
        formData.set("_method", "put");
      }

      this.$axios({
        method: "post",
        url: "blog" + blog_id,
        data: formData,
        headers: { "content-type": "multipart/form-data" },
      })
        .then(() => {
          if (this.action === "update") {
            this.$root.notify(this.$t("successfully_updated"), "is-success");
          } else if (this.action === "create") {
            this.$root.notify(this.$t("successfully_created"), "is-success");
          }
          this.$router.push("/blog/articles");
        })
        .catch((e) => {
          this.clientError(e);
        })
        .finally(() => {
          this.save_loading = false;
        });
    },
    onUpdateFiles(files) {
      this.image = files[0];
    },
  },
};
</script>
