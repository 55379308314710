<template>
  <div class="columns is-multiline is-mobile is-size-7">
    <div class="column is-7-tablet is-12-mobile is-flex is-align-items-center">
      <StatusSelect
        :value="sid"
        :data="details.statuses"
        :logistic-status="details.logistic_status"
        :order-id="orderId"
        :label="$t('status')"
        class="is-flex-grow-1"
        :disabled="!$can('update', 'orders')"
        rules="required"
        vid="sid"
        @input="(id) => (sid = id)"
        @openCancelModal="openCancelOrderModal('ticket')"
      />

      <b-button
        v-if="
          $can('create', 'orders.cancel') &&
          details.logistic_status &&
          details.logistic_status.cancellable
        "
        type="is-danger"
        class="ml-3"
        size="is-small"
        :loading="cancelOrderLoading"
        :disabled="cancelOrderLoading"
        outlined
        @click="openCancelOrderModal('button')"
      >
        {{ $t("cancel") }}
      </b-button>

      <span
        v-if="details.video"
        class="ml-3 is-clickable"
        @click="showVideo = true"
      >
        <b-icon icon="video" type="is-grey-light" />
      </span>
    </div>

    <div class="column is-5-tablet is-12-mobile is-flex is-align-items-center">
      <!-- <b-tag v-if="details.logistic_status">
        {{ details.logistic_status && details.logistic_status.label }}
      </b-tag> -->

      <!-- <template v-else> -->
      <template v-if="sid == 6">
        <b-button
          v-if="
            tracking_numbers.length == 1 &&
            (tracking_numbers[0].tracking_number == '' || tracking_numbers[0].tracking_number == null) &&
            $can('update', 'orders.shipping')
          "
          type="is-primary"
          outlined
          size="is-small"
          tag="input"
          :value="$t('add_tracking_num')"
          @click="openShippedmodal()"
        />
        <b-tag
          v-if="
            tracking_numbers.length > 0 && tracking_numbers[0].tracking_number !== ''&& tracking_numbers[0].tracking_number !== null
          "
          ellipsis
        >
          <a
            :href="tracking_numbers[0] && tracking_numbers[0].link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ tracking_numbers[0] && tracking_numbers[0].tracking_number }}
          </a>
        </b-tag>
        <b-dropdown
          v-if="tracking_numbers.length > 1"
          aria-role="list"
          position="is-bottom-left"
          class="ml-2"
        >
          <b-tag slot="trigger" rounded>
            +{{ tracking_numbers.length - 1 }}
            <b-icon
              icon="chevron-down"
              size="is-small"
              class="is-clickable ml-1"
            />
          </b-tag>

          <template v-for="tracking_number in tracking_numbers">
            <b-dropdown-item
              :key="tracking_number.tracking_number"
              :href="tracking_number.link"
              target="_blank"
              replace
              aria-role="listitem"
            >
              {{ tracking_number.tracking_number }}
            </b-dropdown-item>
          </template>
        </b-dropdown>
        <!-- </template> -->
        <span
          v-if="
            tracking_numbers.length > 0 &&
            tracking_numbers[0].tracking_number !== '' && tracking_numbers[0].tracking_number !== null &&
            $can('update', 'orders.shipping')
          "
          @click="openShippedmodal()"
        >
          <b-icon icon="pencil" size="is-small" class="ml-1 is-clickable" />
        </span>
      </template>
    </div>

    <div class="column is-12">
      <div class="is-flex is-justify-content-space-between">
        <span class="is-size-5">
          <b>{{ $t("payment") }}</b>
          <span v-if="summary.profit" class="is-size-7">
            - {{ $t("gross_margin") }}: {{ summary.profit.formatted }} ({{
              summary.profit.percent
            }}%)
          </span>
        </span>

        <b-dropdown
          aria-role="list"
          position="is-bottom-left"
          class="ml-2"
        >
          <b-button slot="trigger" rounded size="is-small">
            <b-icon icon="dots-vertical" size="is-small" class="is-clickable" />
          </b-button>

          <b-dropdown-item
            v-if="$can('update', 'orders') && summary.balance.value > 0 && (sid != 10)"
            aria-role="listitem"
            @click="showPaymentForm"
          >
            <b-icon icon="plus" size="is-small" />
            {{ $t("new_payment") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$can('update', 'orders') && (sid != 10)"
            aria-role="listitem"
            @click="displayNoteModal"
          >
            <b-icon icon="note-text-outline" size="is-small" />
            {{ $t("note")
            }}{{
              $can("update", "orders.incoterm") ? ` & ${$t("incoterm")}` : ""
            }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$can('update', 'orders.prices') && (sid != 10)"
            aria-role="listitem"
            @click="offerOrder()"
          >
            <b-icon icon="gift-outline" size="is-small" />
            {{ $t("offer_order") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$can('read', 'orders') && (sid != 10)"
            aria-role="listitem"
            @click="sendOrderMail()"
          >
            <b-icon icon="email-multiple-outline" size="is-small" />
            {{ $t("send_confirmation_mail") }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="
              $can('create', 'orders.cancel') && paymentReceived && [6,7,8,9,12,13,14].includes(sid)
            "
            aria-role="listitem"
            @click="showRefundOrderModal = true"
          >
            <b-icon icon="cash-refund" size="is-small" />
            {{ $t("refund_order") }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('update', 'orders.merge') && mergeableWith.length != 0 && (sid != 10)"
            aria-role="listitem"
            @click="displayMergeModal"
          >
            <b-icon icon="merge" size="is-small" />
            {{ $t("merge_orders") }}
          </b-dropdown-item>

          <!-- <b-dropdown-item
            v-if="actions && JSON.stringify(actions) !== '{}'"
            separator
          /> -->

          <b-dropdown-item
            v-if="actions && actions.modify"
            aria-role="listitem"
            @click="showModifyOrderModal = true"
          >
            <b-icon icon="pencil" size="is-small" />
            {{ actions.modify.label }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="actions && actions.return"
            aria-role="listitem"
            @click="returnOrder()"
          >
            <b-icon icon="ticket" size="is-small" />
            {{ actions.return.label }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="actions && actions.cancel"
            aria-role="listitem"
            @click="openCancelOrderModal('ticket')"
          >
            <b-icon icon="close" size="is-small" type="is-danger" />
            {{ actions.cancel.label }}
          </b-dropdown-item>

          <b-dropdown-item 
            v-if="(sid != 9)" 
            separator
          />

          <b-dropdown-item
            v-if="(sid != 9)"
            aria-role="listitem"
            @click="downloadInvoice(orderId)"
          >
            <b-icon icon="download" size="is-small" />
            {{ actions.invoice.label }}
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <ul class="payments bg-cream p-3 mt-2">
        <li
          v-for="p in payments"
          :key="p.id"
          class="columns is-multiline is-mobile"
          :class="p.active ? null : 'bg-deleted'"
        >
          <div class="column is-6">
            <div>
              {{ p.card ? p.card : p.payment_mean }}
              <span class="has-text-grey-light">
                ({{ p.card_number || `${$t("id")}: ${p.id}` }})
              </span>
            </div>
            <b-tag
              size="is-small"
              class="mr-2"
              :type="p.status === 'OK' ? 'is-success' : 'is-danger'"
              :title="p.comment"
              ellipsis
            >
              {{
                p.status === "OK"
                  ? p.amount.value > 0
                    ? $t("received")
                    : $t("refunded")
                  : p.status === null
                  ? $t("pending")
                  : p.comment !== null
                  ? p.comment
                  : $t("declined")
              }}
            </b-tag>
            <b-tag
              v-if="!p.active"
              size="is-small"
              type="is-danger"
              :title="$t('deleted')"
              ellipsis
            >
              {{ $t("deleted") }}
            </b-tag>
          </div>

          <div class="column is-2">
            <div class="editable">
              <span class="edit">
                <input
                  v-show="!(editPayment == null || editPayment != p.id)"
                  :ref="'amount' + p.id"
                  :placeholder="$t('amount')"
                  type="number"
                  autofocus
                  :name="'amount' + p.id"
                  :value="p.amount.value"
                  :class="
                    editPayment == null || editPayment != p.id ? '' : 'show'
                  "
                  @blur="editPayment = null"
                  @keyup.enter="updatePrice($event, p)"
                />
                {{ p.amount && p.amount.formatted }}
                <span
                  v-if="
                    $can('update', 'orders') &&
                    p.status === null &&
                    !p.online && p.active &&
                    hasNonZeroBalance
                  "
                  class="is-clickable"
                  @click="refreshAmount(p)"
                >
                  <b-icon icon="refresh" size="is-small" type="is-success" />
                </span>
              </span>
            </div>
          </div>

          <div class="column is-3">
            <span v-if="p.paid_at">
              {{ p.paid_at.formatted }}
              <span class="is-block has-text-grey">{{ p.paid_at.time }}</span>
            </span>
            <span v-else>-</span>
          </div>

          <div
            v-if="$can('update', 'orders')"
            class="column is-1 has-text-right"
          >
            <b-dropdown
              v-if="p.status != 'KO' && p.payment_mean_id != 12 && p.active"
              :ref="'dropdown' + p.id"
              aria-role="list"
              position="is-bottom-left"
              class="has-text-left"
              :close-on-click="false"             
            >
              <b-icon
                slot="trigger"
                icon="dots-vertical"
                size="is-small"
                class="is-clickable"
              />
              <template v-if="p.status == 'OK' && p.amount && p.amount.value > 0">
                <b-dropdown-item
                  v-if="$can('create', 'orders.cancel')"
                  aria-role="menuitem"
                  :focusable="selected_refund_id != p.id"
                  class="pr-4"
                  @click="startRefund(p)"
                >
                  <span v-if="selected_refund_id !== p.id">
                    <b-icon icon="credit-card-refund-outline" size="is-small" class="mr-1" />
                    {{ $t("refund") }}
                  </span>

                  <ValidationObserver
                    v-else
                    ref="observer"
                    v-slot="{ handleSubmit }"
                    tag="div"
                  >
                    <form autocomplete="off" class="is-flex">
                      <b-input-validation
                        v-model="selected_refund_amount"
                        :expanded="false"
                        size="is-small"
                        rules="required"
                        vid="amount"
                        input-type="number"
                        step=".01"
                        :suffix="summary.currency.symbol"
                        :placeholder="placeholders.price.decimal"
                        :label="$t('amount')"
                        hide-details
                      />
                      <b-button
                        :loading="refund_loading"
                        class="ml-2"
                        size="is-small"
                        :disabled="refund_loading"
                        @click="handleSubmit(() => refund(p))"
                      >
                        <b-icon
                          icon="check"
                          type="is-success"
                          size="is-small"
                        />
                      </b-button>
                    </form>
                  </ValidationObserver>
                </b-dropdown-item>
              </template>
              <template v-if="p.online">
                <template v-if="p.status == 'OK'">
                  <b-dropdown-item
                    v-if="$can('create', 'users.cashback')"
                    aria-role="menuitem"
                    class="pr-4"
                    :focusable="selected_cashback_id != p.id"
                    @click="startCreateCashback(p)"
                  >
                    <span v-if="selected_cashback_id !== p.id">
                      <b-icon icon="cash" size="is-small" class="mr-1" />
                      {{ $t("create_cashback") }}
                    </span>

                    <ValidationObserver
                      v-else
                      ref="observer"
                      v-slot="{ handleSubmit }"
                      tag="div"
                    >
                      <form autocomplete="off" class="is-flex">
                        <b-input-validation
                          v-model="selected_cashback_amount"
                          :expanded="false"
                          size="is-small"
                          rules="required"
                          vid="amount"
                          input-type="number"
                          :suffix="summary.currency.symbol"
                          :placeholder="placeholders.price.decimal"
                          :label="$t('amount')"
                          hide-details
                        />
                        <b-button
                          :loading="create_cashback_loading"
                          class="ml-2"
                          size="is-small"
                          :disabled="create_cashback_loading"
                          @click="handleSubmit(() => createCashback(p))"
                        >
                          <b-icon
                            icon="check"
                            type="is-success"
                            size="is-small"
                          />
                        </b-button>
                      </form>
                    </ValidationObserver>
                  </b-dropdown-item>
                </template>
                <template v-else-if="p.status == null">
                  <b-dropdown-item
                    v-if="!mpId"
                    aria-role="menuitem"
                    @click="OpenEditPrice(p)"
                  >
                    <b-icon icon="pencil" size="is-small" class="mr-1" />
                    {{ $t("edit_amount") }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    aria-role="menuitem"
                    @click="sendPaymentEmail(p.amount.value, p.id)"
                  >
                    <b-icon
                      icon="email-multiple-outline"
                      size="is-small"
                      class="mr-1"
                    />
                    {{ $t("send_payment_link") }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    aria-role="menuitem"
                    @click="deletePayment(p)"
                  >
                    <b-icon
                      icon="delete"
                      type="is-danger"
                      size="is-small"
                      class="mr-1"
                    />
                    {{ $t("delete") }}
                  </b-dropdown-item>
                </template>
              </template>
              <template v-else>
                <b-dropdown-item
                  v-if="$can('update', 'orders.payments') && p.status == null"
                  aria-role="menuitem"
                  @click="received(p.id)"
                >
                  <b-icon icon="check-circle" size="is-small" class="mr-1" />
                  {{ $t("validate_payment") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-else-if="p.status == 'OK'"
                  aria-role="menuitem"
                  @click="cancelReveived(p.id)"
                >
                  <b-icon
                    icon="close-circle-outline"
                    size="is-small"
                    class="mr-1"
                  />
                  {{ $t("invalidate_payment") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!mpId"
                  aria-role="menuitem"
                  @click="OpenEditPrice(p)"
                >
                  <b-icon icon="pencil" size="is-small" class="mr-1" />
                  {{ $t("edit_amount") }}
                </b-dropdown-item>
                <b-dropdown-item aria-role="menuitem" @click="deletePayment(p)">
                  <b-icon
                    icon="delete"
                    type="is-danger"
                    size="is-small"
                    class="mr-1"
                  />
                  {{ $t("delete") }}
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
        </li>

        <!-- new payment line -->
        <transition name="fade" mode="in-out">
          <ValidationObserver
            v-if="payment_form && $can('update', 'orders')"
            ref="observer"
            v-slot="{ handleSubmit }"
            tag="li"
          >
            <form
              autocomplete="off"
              class="columns is-multiline is-mobile is-variable is-2 mt-0"
            >
              <div class="column is-6">
                <b-select-validation
                  v-model="payment.mean"
                  size="is-small"
                  rules="required"
                  :label="$t('payment_mean')"
                  vid="payment_mean"
                >
                  <option
                    v-for="item in details.payment_means"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.mean }}
                  </option>
                </b-select-validation>
              </div>

              <div class="column is-5">
                <b-input-validation
                  v-model="payment.amount"
                  size="is-small"
                  rules="required"
                  vid="payment_amount"
                  input-type="number"
                  :suffix="summary.currency.symbol"
                  :label="$t('amount')"
                  :placeholder="placeholders.price.decimal"
                />
              </div>
              <div v-if="payment.mean" class="column is-1">
                <b-button
                  v-if="
                    !details.payment_means.find((pay) => pay.id == payment.mean)
                      .online
                  "
                  :loading="payment_create_loading"
                  :disabled="payment_create_loading"
                  size="is-small"
                  @click="handleSubmit(() => paymentSave(payment))"
                >
                  <b-icon icon="check" type="is-success" size="is-small" />
                </b-button>

                <b-dropdown v-else aria-role="list" position="is-bottom-left">
                  <div slot="trigger">
                    <b-icon
                      icon="dots-vertical"
                      size="is-small"
                      class="is-clickable"
                    />
                  </div>

                  <b-dropdown-item
                    aria-role="listitem"
                    @click="sendPaymentEmail()"
                  >
                    {{ $t("send_payment_link") }}
                  </b-dropdown-item>
                  <b-dropdown-item aria-role="listitem" @click="payNow()">
                    {{ $t("pay_now") }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </form>
          </ValidationObserver>
        </transition>
        <!-- end of new payment line -->

        <li class="is-flex is-justify-content-space-between mt-2">
          <transition name="fade" mode="in-out">
            <b-button
              v-if="payment_form"
              outlined
              type="is-danger"
              size="is-small"
              @click="closePaymentForm"
            >
              {{ $t("cancel") }}
            </b-button>
          </transition>

          <div class="is-flex-grow-1 has-text-right">
            <b>{{ $t("total_order") }}: {{ summary.total.formatted }}</b>
            <br />
            <b
              :class="
                summary.to_pay.type === null
                  ? 'has-text-success'
                  : 'has-text-danger'
              "
            >
              {{
                summary.to_pay.type === "refund"
                  ? $t("to_refund")
                  : $t("left_to_pay")
              }}: {{ summary.to_pay.formatted }}
            </b>
          </div>
        </li>
      </ul>
    </div>
    <b-modal v-model="showVideo" :width="800">
      <video style="outline: none" width="100%" controls autoplay>
        <source :src="details.video" type="video/mp4" />
      </video>
    </b-modal>

    <b-modal v-model="showNoteModal" :width="520">
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>
              {{ $t("note")
              }}{{
                $can("update", "orders.incoterm") ? ` & ${$t("incoterm")}` : ""
              }}
            </b>
          </div>
          <hr class="my-0" />
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form
            method="post"
            autocomplete="off"
            @submit.prevent="handleSubmit(SendUpdateNote)"
          >
            <b-input-validation
              v-model="newNote"
              class="mb-1"
              vid="note"
              :label="`${$t('note')} (${$t('optional')})`"
              :placeholder="placeholders.order.note"
            />

            <b-select-validation
              v-if="$can('update', 'orders.incoterm')"
              v-model="newIncoterm"
              class="mb-1"
              vid="incoterm"
              :label="`${$t('incoterm')} (${$t('optional')})`"
            >
              <template>
                <option v-for="opt in incoterms" :key="opt" :value="opt">
                  {{ opt }}
                </option>
              </template>
            </b-select-validation>

            <b-button
              class="mr-4"
              type="is-success"
              :loading="loadingNote"
              :disabled="loadingNote"
              tag="input"
              native-type="submit"
              :value="$t('save')"
            >
              {{ $t("save") }}
            </b-button>
            <b-button :disabled="loadingNote" @click="showNoteModal = false">
              {{ $t("cancel") }}
            </b-button>
          </form>
        </ValidationObserver>
      </Card>
    </b-modal>
    <b-modal v-model="showMergeModal" :width="520" :height="520">
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("merge_orders") }}</b>
          </div>
          <hr class="my-0" />
        </div>

        <div
          class="pb-6 is-flex is-flex-direction-row is-align-items-top is-justify-content-space-between"
        >
          <b-field :label="$t('merge_orders')">
            <b-taginput
              ref="taginput"
              v-model="mergeables"
              class="merge"
              type="is-default"
              expanded
              open-on-focus
              autocomplete
              :data="mappedMergeables"
              :placeholder="mergeables.length == 0 ? $t('choose') : ''"
              @add="
                (obj) => {
                  removeFromFilteredData();
                }
              "
              @remove="(obj) => mappedMergeables.unshift(obj)"
            />
          </b-field>

          <div
            class="is-flex is-align-items-top is-justify-content-space-between"
          >
            <b-button
              class="mr-4 ml-4"
              type="is-success"
              tag="input"
              :value="$t('validate')"
              @click="merge"
            />

            <b-button @click="showMergeModal = false">
              {{ $t("cancel") }}
            </b-button>
          </div>
        </div>
      </Card>
    </b-modal>
    <b-modal v-model="showCancelOrderModal" :width="420">
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>
              {{ $t("cancel") }}
            </b>
          </div>
          <hr class="my-0" />
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form
            method="post"
            autocomplete="off"
            @submit.prevent="handleSubmit(cancelOrder)"
          >
            <b-input-validation
              v-model="cancelOrderReason"
              input-type="textarea"
              rows="2"
              rules="required"
              vid="reason"
              :label="$t('reason')"
              :placeholder="placeholders.user.deleteReason"
            />
            <b-checkbox
              v-if="cancelFrom != 'button'"
              v-model="CancelOrderRestock"
              type="is-info"
            >
              {{ $t("restock_products") }}
            </b-checkbox>
            <b-checkbox
              v-if="cancelFrom == 'ticket'"
              class="ml-5"
              :disabled="mpId"
              v-model="cancelOrderCreateTicket"
              type="is-info"
            >
              {{ $t("create_ticket") }}
            </b-checkbox>
            <div class="mt-5">
              <b-button
                class="mr-4"
                type="is-success"
                :loading="cancelOrderLoading"
                :disabled="cancelOrderLoading"
                tag="input"
                native-type="submit"
                :value="$t('confirm')"
              >
                {{ $t("confirm") }}
              </b-button>
              <b-button
                :disabled="cancelOrderLoading"
                @click="showCancelOrderModal = false"
              >
                {{ $t("cancel") }}
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </Card>
    </b-modal>
    <b-modal v-model="showRefundOrderModal" :width="420">
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>
              {{ $t("refund_order") }}
            </b>
          </div>
          <hr class="my-0" />
        </div>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form
            method="post"
            autocomplete="off"
            @submit.prevent="handleSubmit(refundOrder)"
          >
            <b-input-validation
              v-model="refundOrderReason"
              input-type="textarea"
              rows="2"
              rules="required"
              vid="refundreason"
              :label="$t('reason')"
              :placeholder="placeholders.user.deleteReason"
            />
            <b-checkbox
              v-if="restockableStatuses.includes(sid)"
              v-model="refundOrderRestock"
              type="is-info"
            >
              {{ $t("restock_products") }}
            </b-checkbox>
            <div class="mt-5">
              <b-button
                class="mr-4"
                type="is-success"
                :loading="refundOrderLoading"
                :disabled="refundOrderLoading"
                tag="input"
                native-type="submit"
                :value="$t('confirm')"
              >
                {{ $t("confirm") }}
              </b-button>
              <b-button
                :disabled="refundOrderLoading"
                @click="showRefundOrderModal = false"
              >
                {{ $t("cancel") }}
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </Card>
    </b-modal>
    <b-modal
      v-if="actions && actions.modify"
      v-model="showModifyOrderModal"
      :width="420"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>
              {{ actions.modify.label }}
            </b>
          </div>
          <hr class="my-0">
        </div>
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit }"
          tag="div"
        >
          <form
            method="post"
            autocomplete="off"
            @submit.prevent="handleSubmit(modifyOrder)"
          >
            <b-input-validation
              v-model="modifyOrderReason"
              input-type="textarea"
              rows="2"
              rules="required"
              vid="reason"
              :label="$t('reason')"
              :placeholder="placeholders.user.deleteReason"
            />
            <div class="mt-5">
              <b-button
                class="mr-4"
                type="is-success"
                :loading="modifyOrderLoading"
                :disabled="modifyOrderLoading"
                tag="input"
                native-type="submit"
                :value="$t('confirm')"
              >
                {{ $t("confirm") }}
              </b-button>
              <b-button
                :disabled="modifyOrderLoading"
                @click="showModifyOrderModal = false"
              >
                {{ $t("cancel") }}
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

import StatusSelect from "./StatusSelect";
export default {
  components: {
    StatusSelect,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    incoterms: {
      type: Array,
      default: () => [],
    },
    mergeableWith: {
      type: Array,
      default: () => [],
    },
    mpId: {
      type: String,
      default: null,
    },
    paymentReceived: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      mergeables: [],
      mappedMergeables: [],
      actions: this.options.actions,
      details: this.options.details,
      payments: this.options.payments,
      summary: this.options.summary,
      //
      editPayment: null,
      sid: null,
      factoring: null,
      tracking_numbers: null,
      selected_refund_id: null,
      selected_refund_amount: null,
      refund_loading: false,
      selected_cashback_id: null,
      selected_cashback_amount: null,
      create_cashback_loading: false,
      payment_form: false,
      payment_create_loading: false,
      payment: {
        mean: null,
        amount: null,
      },
      showVideo: false,
      cancelOrderLoading: false,
      showNoteModal: false,
      showCancelOrderModal: false,
      refundOrderLoading: false,
      showRefundOrderModal: false,
      refundOrderRestock: false,
      refundOrderReason: "",
      CancelOrderRestock: true,
      cancelOrderReason: "",
      cancelOrderCreateTicket: true,
      cancelFrom: "",
      showModifyOrderModal: false,
      modifyOrderLoading: false,
      modifyOrderReason: "",
      restockableStatuses: [2,3,4,5,15],

      newNote: "",
      newIncoterm: "",
      loadingNote: false,
      showMergeModal: false,
      products: [],
      returnTitle: "",
      returnFilters: {},
      returnActions: {},
      returnForm: {},
      selectAllDisabled: false,
    };
  },
  computed: {
    hasNonZeroBalance() {
      const balanceAbs = Math.abs(this.summary.balance.value);
      const toPayAbs = Math.abs(this.summary.to_pay.value);
      const totalAbs = Math.abs(this.summary.total.value);

      const isBalanceAndToPayEqual = (balanceAbs - toPayAbs) == 0;
      const result = !isBalanceAndToPayEqual && (balanceAbs + toPayAbs - totalAbs) !== 0;

      return result;
    }
  },
  created() {
    this.refreshDetails("created");
    this.mappedMergeables = this.mergeableWith;
    this.filterMergeables();
    if (this.mpId) this.cancelOrderCreateTicket = false;
  },
  mounted() {
    this.$bus.$on("update-order-options", (params) => {
      if (params.summary) this.summary = params.summary;
      if (params.details) {
        this.details = params.details;
        this.refreshDetails();
      }
      if (params.actions) {
        this.updateActions(params.actions);
      }

      this.payment_form = false;
    });

    this.$bus.$on("after-online-payment", (payment) => {
      this.payments.push(payment);
      this.payment_form = false;
    });

    this.$bus.$on("update-form-payments", (params) => {
      this.payments = params.payments;
    });
  },
  destroyed() {
    this.$bus.$off("update-order-options");
    this.$bus.$off("after-online-payment");
    this.$bus.$off("update-form-payments");
  },
  methods: {
    removeFromFilteredData(obj) {
      var obj = this.mergeables[this.mergeables.length - 1];
      var index = this.mappedMergeables.indexOf(obj);
      if (index !== -1) {
        this.mappedMergeables.splice(index, 1);
      }
    },

    filterMergeables() {
      let filtered = this.mergeableWith.filter((option) => {
        return this.mergeables.findIndex((s) => s.id == option.id) == -1;
      });
      this.mappedMergeables = filtered;
    },

    merge() {
      this.$axios
        .post(`ecommerce/orders/merge`, {
          destination: this.orderId,
          source: this.mergeables,
        })
        .then((res) => {
          this.$root.notify(
            this.$i18n.t("request_successfully_sent"),
            "is-success"
          );
          this.showMergeModal = false;
        })
        .catch((e) => this.clientError(e))
        .finally(() => {
          //
        });
    },
    offerOrder() {
      this.$buefy.dialog.confirm({
        message: this.$t("are_you_sure"),
        type: "is-info",
        hasIcon: true,
        trapFocus: true,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        onConfirm: () => {
          this.$axios
            .get(`ecommerce/orders/${this.orderId}/offer`)
            .then((res) => {
              this.details = res.data.details;
              this.summary = res.data.summary;
              this.payments = res.data.payments;
              this.payment_form = false;
              let status = this.details.statuses.filter(
                (s) => s.selected === true
              );
              this.status_id = status[0]["id"];
              this.$bus.$emit("update-order", res.data);
            })
            .catch((e) => this.clientError(e));
        },
      });
    },
    returnOrder() {
      let options = {
        level: 1,
        orderId: this.orderId,
      };
      this.openDrawer("orderClaim", options);
    },
    modifyOrder() {
      let data = { reason: this.modifyOrderReason };
      this.$axios
        .post("ecommerce/orders/" + this.orderId + "/hold", data)
        .then((res) => {
          let msg = res.data.message;
          this.$root.notify(msg, "is-success");
          this.details = res.data.order.details;
          this.actions = res.data.order.actions;
          this.$bus.$emit("update-order-options", {
            id: this.orderId,
            details: res.data.order.details,
            actions: res.data.order.actions,
            summary: res.data.order.summary,
            items: res.data.order.items,
          });
        })
        .catch((e) => {
          this.$root.clientError(e);
        });
    },
    openCancelOrderModal(from) {
      this.showCancelOrderModal = true;
      this.cancelFrom = from;
    },
    cancelOrder() {
      this.cancelOrderLoading = true;
      let method = "POST";
      let url = `ecommerce/orders/${this.orderId}`;
      let data = { reason: this.cancelOrderReason };

      if (this.cancelFrom == "ticket") {
        url = url + "/cancel"
        data.restock = this.CancelOrderRestock;
        data.create_ticket = this.cancelOrderCreateTicket;
      } else if (this.cancelFrom == "select") {
        method = "PATCH";
        data.restock = this.CancelOrderRestock;
        data.status_id = 10;
      } else if (this.cancelFrom == "button") {
        url = url + "/cancel-packing";
      }

      this.$axios({
        method: method,
        url: url,
        data: data
      })
      .then((res) => {
        this.showCancelOrderModal = false;
        this.$bus.$emit("update-order-options", {
          id: this.orderId,
          details: res.data.details,
          actions: res.data.actions,
          summary: res.data.summary,
          items: res.data.items,
        });
        this.$bus.$emit("update-order-payments",{
          payments: res.data.payments
        });
        this.payments = res.data.payments;
        this.$root.notify(this.$t("successfully_updated"), "is-success");
      })
      .catch((e) => this.clientError(e))
      .finally(() => {
        this.cancelOrderLoading = false;
      });
    },
    // refundOrder() {
    //   this.$buefy.dialog.confirm({
    //     message: this.$t("are_you_sure"),
    //     type: "is-info",
    //     hasIcon: true,
    //     trapFocus: true,
    //     confirmText: this.$t("confirm"),
    //     cancelText: this.$t("cancel"),
    //     onConfirm: () => {
    //       this.$axios
    //         .get(`ecommerce/orders/${this.orderId}/refund`)
    //         .then((res) => {
    //           this.details = res.data.details;
    //           this.summary = res.data.summary;
    //           this.payments = res.data.payments;
    //           this.payment_form = false;
    //           let status = this.details.statuses.filter(
    //             (s) => s.selected === true
    //           );
    //           this.status_id = status[0]["id"];
              
    //           this.$bus.$emit("update-order-options", {
    //             id: this.orderId,
    //             details: res.data.details,
    //             actions: res.data.actions,
    //             summary: res.data.summary,
    //             items: res.data.items
    //           });
    //         })
    //         .catch((e) => this.clientError(e));
    //     },
    //   });
    // },
    refundOrder() {
      this.refundOrderLoading = true;
      let data = { 
        restock: this.refundOrderRestock,
        reason: this.refundOrderReason
       };
      this.$axios
        .post(`ecommerce/orders/${this.orderId}/refund`, data)
        .then((res) => {
          this.showRefundOrderModal = false;
          this.details = res.data.details;
          this.summary = res.data.summary;
          this.payments = res.data.payments;
          this.payment_form = false;
          let status = this.details.statuses.filter(
            (s) => s.selected === true
          );
          this.status_id = status[0]["id"];
          
          this.$bus.$emit("update-order-options", {
            id: this.orderId,
            details: res.data.details,
            actions: res.data.actions,
            summary: res.data.summary,
            items: res.data.items
          });
          this.$bus.$emit("update-order-payments",{
            payments: res.data.payments
          });
        })
        .catch((e) => this.clientError(e))
        .finally(() => {
          this.refundOrderLoading = false;
        });
    },
    sendOrderMail() {
      this.$buefy.dialog.confirm({
        message: this.$t("are_you_sure"),
        type: "is-info",
        hasIcon: true,
        trapFocus: true,
        confirmText: this.$t("confirm"),
        cancelText: this.$t("cancel"),
        onConfirm: () => {
          this.$axios
            .get(`ecommerce/orders/${this.orderId}/mail`)
            .then(() => {
              this.$root.notify(
                this.$i18n.t("request_successfully_sent"),
                "is-success"
              );
            })
            .catch((e) => this.clientError(e));
        },
      });
    },
    OpenEditPrice(p) {
      this.editPayment = p.id;
      this.$refs["amount" + p.id][0].focus();
      this.closeDrop(p.id);
    },
    updatePrice(e, p) {
      this.$axios
        .put("ecommerce/payments/" + p.id, {
          amount: e.target && e.target.value && parseFloat(e.target.value),
        })
        .then((res) => {
          this.$root.notify(
            this.$i18n.t("request_successfully_sent"),
            "is-success"
          );
          this.summary = res.data.summary;
          this.payments = res.data.payments;
          this.editPayment = null;
        })
        .catch((e) => this.clientError(e));
    },
    cancelReveived(p_id) {
      this.$axios
        .put("ecommerce/payments/" + p_id, {
          status: null,
        })
        .then((res) => {
          this.$root.notify(
            this.$i18n.t("request_successfully_sent"),
            "is-success"
          );
          this.$bus.$emit("update-order-payments",{
            payments: res.data.payments
          });
          this.$bus.$emit("update-order-options", {
            id: this.orderId,
            details: res.data.details,
            actions: res.data.actions,
            summary: res.data.summary,
            items: res.data.items
          });
          this.summary = res.data.summary;
          this.payments = res.data.payments;
          this.closeDrop(p_id);
        })
        .catch((e) => this.clientError(e));
    },
    closeDrop(p_id) {
      this.$refs["dropdown" + p_id][0].toggle();
    },
    refreshDetails(from = null) {
      let status = this.details.statuses.filter((s) => s.selected);

      this.sid = status[0]["id"];

      if (from !== "created") {
        // update order data on list
      }

      this.factoring = this.details.factoring;
      this.tracking_numbers = this.details.shippings;
    },
    updateActions(actions) {
      this.actions = actions;
    },
    showPaymentForm() {
      this.payment_form = true;
      this.payment.amount = this.summary.to_pay.value;
    },
    closePaymentForm() {
      this.payment_form = false;
      this.payment = {
        mean: null,
        amount: null,
      };
    },
    received(payment_id) {
      this.$axios
        .put("ecommerce/payments/" + payment_id, {
          status: "OK",
          paid_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        })
        .then((res) => {
          this.$root.notify(
            this.$i18n.t("request_successfully_sent"),
            "is-success"
          );
          this.$bus.$emit("update-order-payments",{
            payments: res.data.payments
          });
          this.$bus.$emit("update-order-options", {
            id: this.orderId,
            details: res.data.details,
            actions: res.data.actions,
            summary: res.data.summary,
            items: res.data.items
          });
          this.summary = res.data.summary;
          this.payments = res.data.payments;
          this.closeDrop(payment_id);
        })
        .catch((e) => this.clientError(e));
    },
    deletePayment(p) {
      this.$axios
        .delete("ecommerce/payments/" + p.id)
        .then((res) => {
          this.details.statuses = res.data.details.statuses;
          this.summary = res.data.summary;
          this.payments = res.data.payments;
          this.closeDrop(p.id);
        })
        .catch((e) => {
          this.clientError(e);
        });
    },
    startRefund(p) {
      this.selected_cashback_id = null;
      if (this.summary.to_pay.value > 0) return;
      let to_pay = this.summary.to_pay.value * -1;
      if (
        this.selected_refund_id === null ||
        this.selected_refund_id !== p.id
      ) {
        this.selected_refund_id = p.id;
        this.selected_refund_amount =
          to_pay >= p.amount.value ? p.amount.value : to_pay;
      }
    },
    refund(p) {
      this.refund_loading = true;
      this.$axios
        .post("ecommerce/payments/" + p.id + "/refund", {
          amount: this.selected_refund_amount,
        })
        .then((res) => {
          this.summary = res.data.summary;
          this.payments = res.data.payments;
          this.$root.notify(
            this.$i18n.t("request_successfully_sent"),
            "is-success"
          );
          this.$bus.$emit("update-order-options", {
            id: this.orderId,
            details: res.data.details,
            actions: res.data.actions,
            summary: res.data.summary,
            items: res.data.items
          });
          this.closeDrop(p.id);
        })
        .catch((e) => this.clientError(e))
        .finally(() => {
          this.refund_loading = false;
          this.selected_refund_id = null;
          this.selected_refund_amount = null;
        });
    },
    refreshAmount(p) {
      this.$axios
        .put("ecommerce/payments/" + p.id, {
          amount: this.summary.to_pay.value,
        })
        .then((res) => {
          this.details.statuses = res.data.details.statuses;
          this.summary = res.data.summary;
          this.payments = res.data.payments;
        })
        .catch((e) => this.clientError(e));
    },
    startCreateCashback(p) {
      this.selected_refund_id = null;
      if (this.summary.balance.value > 0) return;
      let balance = this.summary.balance.value * -1;
      if (
        this.selected_cashback_id === null ||
        this.selected_cashback_id !== p.id
      ) {
        this.selected_cashback_id = p.id;
        this.selected_cashback_amount =
          balance >= p.amount.value ? p.amount.value : balance;
      }
    },
    createCashback(p) {
      this.create_cashback_loading = true;
      this.refund_cashback_menu_id = p.id;
      this.$axios
        .post("ecommerce/payments/" + p.id + "/cashback", {
          amount: this.selected_cashback_amount,
        })
        .then((res) => {
          this.summary = res.data.summary;
          this.payments = res.data.payments;
          this.$root.notify(
            this.$i18n.t("request_successfully_sent"),
            "is-success"
          );
          this.closeDrop(p.id);
        })
        .catch((e) => this.clientError(e))
        .finally(() => {
          this.create_cashback_loading = false;
          this.selected_cashback_id = null;
          this.selected_cashback_amount = null;
          this.refund_cashback_menu_id = null;
        });
    },
    paymentSave() {
      this.payment_create_loading = true;
      this.$axios
        .post("ecommerce/orders/" + this.orderId + "/payments", {
          payment_mean_id: this.payment.mean,
          amount: this.payment.amount,
        })
        .then((res) => {
          this.details = res.data.details;
          this.summary = res.data.summary;
          this.payments = res.data.payments;
          this.payment_form = false;
          let status = this.details.statuses.filter((s) => s.selected === true);
          this.status_id = status[0]["id"];
        })
        .catch((e) => this.clientError(e))
        .finally(() => (this.payment_create_loading = false));
    },
    sendPaymentEmail(p_amount, p_id) {
      this.$axios
        .post("ecommerce/orders/" + this.orderId + "/payments/mail", {
          amount: p_amount ? p_amount : this.payment.amount,
        })
        .then(() => {
          if (p_id) this.closeDrop(p_id);
          this.$root.notify(
            this.$i18n.t("request_successfully_sent"),
            "is-success"
          );
        })
        .catch((e) => this.clientError(e));
    },
    payNow() {
      this.$root.openDrawer("paymentWidget", {
        level: 1,
        orderId: this.orderId,
        amount: this.payment.amount,
      });
    },
    displayNoteModal() {
      this.showNoteModal = true;
      this.newNote = this.details.notes;
      if (this.$can("update", "orders.incoterm"))
        this.newIncoterm = this.details.incoterm;
    },
    SendUpdateNote() {
      this.loadingNote = true;
      let data = { notes: this.newNote, incoterm: null };
      if (this.$can("update", "orders.incoterm"))
        data.incoterm = this.newIncoterm;
      this.$axios
        .patch(`ecommerce/orders/${this.orderId}`, data)
        .then((res) => {
          this.showNoteModal = false;
          this.$bus.$emit("update-order-note-icoterm", {
            notes: this.newNote,
            incoterm: this.newIncoterm,
          });
        })
        .catch((e) => this.$root.clientError(e))
        .finally(() => (this.loadingNote = false));
    },
    displayMergeModal() {
      this.showMergeModal = true;
    },
    openShippedmodal() {
      this.$bus.$emit("open-shipped-modal", {});
    },
  },
};
</script>
